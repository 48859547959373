@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap');

:root {
  /* Colors */
  --primary-color: #456345;
  --primary-color-darker: #455845;
  --primary-color-dark: #3c533c;
  --accent-color: #C59069;

  /* Fonts */ 
  --font-family: 'Raleway';

  --fw-regular: 400;
  --fw-medium: 500;
  --fw-bold: 700;

    /* 12vw + 1rem is what it wants to be, but it will never be smaller than 5rem nor bigger than 8rem */
  --fs-xl: 4rem;
  --fs-large: 3.8rem;
  --fs-medium: 1.8rem;
  --fs-small: 1.3rem;

  /* content padding */
  --inner-content: 2rem;
} 

@media (min-width: 500px) {
  :root {
    --inner-content: 3rem;
   --fs-large: 4.3rem;
    --fs-xl: clamp(5rem, 9vw, 9rem);
    /* --fs-large: 4rem; */
  }
}

@media (min-width: 800px) {
  :root {
    --fs-xl: clamp(5rem, 4vw, 10rem);
    --fs-large: 5rem;
  }
}

@media (min-width: 1400px) {
  :root {
    --inner-content: 5rem;
  }
}


*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: var(--font-family);
}

/* define font root size -> define what 1rem is
font size = normaal 16px. Wij willen dit veranderen naar 10px 
de gebruiker moet dit echter kunnen aanpassen en daarom maken we het relatief met percentages
normaal fontsize = 16px. 62.5% * 16px = 10px. Als de gebruiker nu de root font-size verandert naar
18px, dan wordt onze font-size ook groter doordat het met percentages werkt ipv harde pixels.
1 rem = root fontsize -> so now: 1rem = 10px instead of 1rem = 16px */
html {
  font-size: 62.5%; /* 1rem = 10px; 10px/16px = 62.5% */
}

body {
  box-sizing: border-box;
}